import React from 'react'
import { graphql } from 'gatsby'
import { PrismicPageDataBodyHeroSlider } from 'src/typings/generated/graphql'

import HeroSlider, {
  HeroSliderContent,
  HeroSliderItem,
} from 'src/components/organisms/heroes/heroSlider'

type Props = {
  slice: PrismicPageDataBodyHeroSlider
}

const HeroSliderSlice = ({ slice }: Props) => {
  const content: HeroSliderContent = {
    anchor: slice.primary.anchor ?? undefined,
    items: slice.items.map(
      item =>
        ({
          headline: item.headline ?? undefined,
          eyebrowText: item.eyebrow_text ?? undefined,
          image: item.image?.gatsbyImageData ?? undefined,
          imageAlt: item.image?.alt ?? undefined,
          link: item.link ?? undefined,
          mobileImage:
            item.image?.thumbnails?.mobile?.gatsbyImageData ?? undefined,
          mobileImageAlt: item.image?.thumbnails?.mobile?.alt ?? undefined,
          linkText: item.link_text ?? undefined,
          videoUrl: item.video?.url ?? undefined,
          mobileVideoUrl: item.mobile_video?.url ?? undefined,
          showOverlay: item.show_overlay ?? true,
        } as HeroSliderItem)
    ),
  }

  return <HeroSlider content={content} />
}

export default HeroSliderSlice

export const HeroSliderFragment = graphql`
  fragment PageDataBodyHeroSlider on PrismicPageDataBodyHeroSlider {
    id
    primary {
      anchor
    }
    items {
      eyebrow_text
      headline
      image {
        gatsbyImageData
        alt
        thumbnails {
          mobile {
            gatsbyImageData
            alt
          }
        }
      }
      link_text
      link {
        ...BasicLinkFragment
      }
      video {
        url
      }
      mobile_video {
        url
      }
      show_overlay
    }
  }
`
